<script>
  import { params } from "@roxi/routify";
  import ShopifyEmbed from "../../components/ShopifyEmbed.svelte";
  import Locale from "../../locale/Locale.svelte";
  import { getThankYouMessage } from "../../utils";
  import { _, locale, init } from "svelte-i18n";

  const { orderID = null } = $params;
  let language = localStorage.getItem("enrol-app-locale");
  let isOnline = false;
  locale.set(language);
  init({
    fallbackLocale: "en",
    initialLocale: language,
  });
  let thank_you_message, price_value, firstName, lastName;
  $: {
    if (thank_you_message) {
      thank_you_message = thank_you_message
        .replaceAll("{orderID}", orderID)
        .replaceAll("{firstName}", firstName)
        .replaceAll("{lastName}", lastName);

      if (price_value) {
        thank_you_message = thank_you_message.replaceAll(
          "{price_value}",
          price_value || ""
        );
      }
    }
  }
  getThankYouMessage(orderID).then((res) => {
    if (res) {
      thank_you_message = res.price.variant.product.thank_you_message;
      firstName = res.first_name;
      lastName = res.last_name;
      price_value = res.price.value;
      isOnline = res.price.variant.product.code.includes("online");
    }
  });
</script>

<Locale />
{#if thank_you_message}
  <div style="text-align: justify;">
    <br />
    {@html thank_you_message
      .replaceAll("{Shopify1}", "")
      .replaceAll("{shopify1}", "")}
    {#if thank_you_message.includes("{Shopify1}") || thank_you_message.includes("{shopify1}")}
      <h2>{$_("success.Add a Gwersi Storage Box to your order?")}</h2>
      <ShopifyEmbed />
    {/if}
  </div>
{:else}
  <h2>Congratulations on continuing your Druid Journey</h2>

  <p>
    <strong>
      Your enrolment has been registered.
      {#if orderID}
        Please take a note of your order number: {orderID} and check your inbox in
        a moment for your order.
      {:else}
        Please check your inbox in a moment for your order confirmation.
      {/if}
      If you don’t see it, try checking your spam folder.
    </strong>
  </p>

  <p>
    Our wonderful and efficient admin team will process your registration within
    the next few working days, and as soon as you are enrolled on the course you
    will receive a further email with more details &amp; information.
  </p>

  <p>
    Meanwhile, while you wait for your package to arrive, here are some things
    to look at:
  </p>

  <ul>
    <li>
      <a href="https://www.facebook.com/druidry/"
        >Check out our Facebook page and look out for ’Tea with a Druid’ - the
        live broadcasts there every Monday 8pm UK time.</a
      >
    </li>
    <li>
      <a href="http://www.druidcast.libsyn.com/"
        >Have a listen to ‘Druidcast’ - with over 150 episodes to choose from.</a
      >
    </li>
    <li>
      <a href="https://www.druidry.org/library"
        >have a look at the order’s online library - filled with fascinating
        contributions from members all over the world.</a
      >
    </li>
  </ul>

  <p>With many thanks,</p>
  <p>Yours under the oaks,</p>
  <p>All of us in the OBOD office</p>

  <hr />

  {#if !isOnline}
    <h2>
      Check out the <a href="https://store.druidry.org/">OBOD online store</a>
      for the latest range of recommended books, gifts and accessories personally
      selected by us
    </h2>

    <ShopifyEmbed />
  {/if}
{/if}
<div class="btn-container">
  <a class="btn btn--primary" href={$_("success.back_to_website_url")}
    >{$_("success.Back to the OBOD website")}</a
  >
</div>

<style>
  p,
  ul {
    margin: 0.5em 0;
  }

  p {
    text-align: justify;
  }

  li {
    margin-left: 1em;
    font-style: italic;
  }

  hr {
    margin: 1rem 0;
  }
</style>
